
import { Options, Vue } from "vue-property-decorator";
import GuestLayout from "@/layout/GuestLayout.vue";

@Options({
  components: { GuestLayout },
})
export default class RegisterSuccess extends Vue {
  private goQrCodeScanner() {
    this.$router.push({ name: "QrCodeScannerStudent" });
  }

  public mounted() {
    this.$nextTick(() => {
      const huraEl = document.getElementById("hura");
      huraEl?.classList.add("registration-success-img-wrapper");
    });
  }

  public unmounted() {
    const huraEl = document.getElementById("hura");
    huraEl?.classList.remove("registration-success-img-wrapper");
  }
}
